* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}
:root {
  --primary-Fontcolor: #484848;
  --primary-color: #DB5338  ;
  --secondary-color: #7C1F19;
  --primary-background-color: #f1f8fe;
  --primary-background-color-light: #f7fbff;
  --white-color: #fff;
  --secondary-background-color: #348efe;
  --primary-button-color: #348efe;
  --nav-color: #484848;
  --navlink-Activecolor: #237fd1;
  --balck-color: #1e1e1e;
  --bglight: #fbfbfb;
  --bglight2: #f4f4f9;
  --bglight3: #e0e5e7;
  --tertiary-color: #6a6a6a;
  --btnGradient1: #ee4566;
  --btnGradient2: #c7bb51;
  --captionprimary: #1e2329;
  --goldGradient1: #f6c858;
  --goldGradient2: #c9961a;
  --platinumGradient1: #bdbabc;
  --platinumGradient2: #616563;

}
html,
body {
  width: 100vw;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  color: var(--primary-Fontcolor);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.47;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

html {
  min-height: 100vh;
  scroll-behavior: smooth;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

img {
  width: 100%;
  height: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  color: var(--white-color);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

::selection {
  background: #2196f3;
  text-shadow: none;
  color: var(--white-color);
}

moz::selection {
  background: var(--primary-color);
  text-shadow: none;
  color: var(--white-color);
}
p {
  color: var(--primary-Fontcolor);
  line-height: 24px;
}
a,
a:hover {
  text-decoration: none;
}
.logo {
  width: 110px;
}
.loginBtn {
  color: var(--white-color) !important;
  padding: 6px 23px !important;
  margin-left: 10px;
  margin-bottom: 11px;
  transition: 0.4s;
  border-radius: 4px;
  text-align: center;
  transition: 0.4s;
  border: 1px solid var(--white-color);
   
}
.bannerBtn {
  color: var(--white-color) !important;
  padding: 16px 21px !important;
  margin-top: 10px;
  transition: 0.4s;
  border-radius: 4px;
  text-align: center;
  transition: 0.4s;
  background-color: var(--btnGradient1);
  background-image: linear-gradient(
    to right,
    var(--btnGradient1),
    var(--btnGradient2) 80%
  );
  text-transform: uppercase;
  display: block;
  float: left;
  font-weight: 500;
}
.dxstoxLNav .navbar-collapse {
  flex-basis: unset;
  justify-content: right;
}
.dxstoxLNav.navbar-expand-lg {
  justify-content: right;
  padding-top: 15px;
  padding-bottom: 15px;
}
.primryColr
{
  color: var(--primary-color);
}
.customeNavbar-nav
{
  justify-content: right;
  display: flex;
}
.customeNavbar-nav li a
{
color: var(--primary-color);
}
.dxstoxLNav.navbar-light .navbar-nav .nav-link {
  color: var(--nav-color);
  font-weight: 500;
  font-size: 14px;
  padding: 10px 7px;
}
.dxstoxLNav.navbar-light .navbar-nav .active .nav-link {
  color: #ffeb3b;
}
.dxstoxLNav.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary-color);
}
.dfViedo {
  width: 100%;
}
.alignceneter {
  align-items: center;
}
.bannersection {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #f5f5f5;
}
.LogoRegister
{
  width: 160px;
}
.Logofooter
{
  color: var(--white-color) !important;
  padding: 6px 23px !important;
  margin-top: 10px;
  transition: 0.4s;
  border-radius: 4px;
  text-align: center;


  display: inline-block;
  width: 200px;
}
#navbarSupportedContent
{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#navbarSupportedContent .customeNavbar-nav
{
  justify-content: space-between;
}
.navbar-light .navbar-toggler {
  color: rgb(188 34 93);
  border: 0 !important;
  font-size: 24px;
}
.bannerContainer
{
  width: 100%;
  position: relative;
  margin-top: -100px;
}
.bannerContainer:before
{
  content: "";
  width: 100%;
  position: absolute;
  background: url("../../../../src/images/rgstrFrmBg.webp") right;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 50% 100%;
}
.bannerAlgmnt
{
  padding: 100px 0;
}
/* .rgstrFormBox .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input, .rgstrFormBox  .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1.1em;
  padding: 10px 14px;
  font-size: 13px;
}
.rgstrFormBox .css-oh5ezv-MuiFormLabel-root-MuiInputLabel-root{
  color: #637381;
  line-height: 1em;
  font-size: 13px;
  top: -6px;
}
.rgstrFormBox .css-1ud3fxb-MuiInputBase-root-MuiOutlinedInput-root, .rgstrFormBox  .css-u5rpt6-MuiInputBase-root-MuiOutlinedInput-root
{
  border-radius: 4px;
}
.rgstrFormBox .css-1lwbda4-MuiStack-root>:not(style)+:not(style) {
  margin-top: 8px;
}
.rgstrFormBox .css-1ql7g3c-MuiButtonBase-root-MuiIconButton-root
{
  font-size: 1rem;
}
.rgstrFormBox  .css-1fijpi2-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root
{
    font-size: 14px;
    padding: 4px 22px;
    border-radius: 4px;
    height: 43px;
   }
.rgstrFormBox .css-1he1f3o-MuiButtonBase-root-MuiRadio-root
{
  padding: 2px;
}
.rgstrFormBox .css-1he1f3o-MuiButtonBase-root-MuiRadio-root svg {
  font-size: 18px;
}
.rgstrFormBox  .css-jpnrz7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  line-height: 1;
  font-size: 13px;
  margin-left: 11px;
  color: #606060;
}
.rgstrFormBox  .css-qfz70r-MuiFormGroup-root {
  justify-content: space-between;
}
.rgstrFormBox   .css-18zwyhk-MuiButtonBase-root-MuiRadio-root.Mui-checked
{
  color: var(--primary-color) !important;
} */


.titlemain
{
  font-weight: 600;
    font-size: 25px;
    line-height: 28px;
}
.Linktext, .Linktext:hover
{
  color: var(--primary-color);
}
.titlemainSub
{
  font-size: 17px;
    color: #303030;
    font-weight: 300;
    letter-spacing: .5px;
    margin-top: 18px;
}
.secndaryTirle {
  font-size: 15px;
  letter-spacing: 1px;
  color: var(--btnGradient2);
  font-weight: 600;
}
.bannrcaptn {
  margin-top: 0;
  margin-bottom: 13px;
  font-size: 13px;
  color: #4c4c4cc4;
  font-weight: 300;
  line-height: 18px;
}
.Bannertitle {
  font-weight: 600;
  color: var(--balck-color);
  font-size: 31px;
  line-height: 40px;
}
.LognBtnFooterBox
{
  background: url("../../../../src/images/rgstrFrmBg.webp");
  padding: 50px 60px;
  background-size: cover;
  text-align: center;
  color: #fff;
}
.LognBtnFooterBox h3{
  text-align: center;
  color: #fff;
  font-weight: 600;
 }
 .LognBtnFooterBox p{
  text-align: center;
  color: #d5d0d0;
  font-weight: 200;
  font-size: 14px;
  letter-spacing: .2px;
 }
 .loginBtnFooter{
  color: var(--white-color) !important;
  padding: 6px 23px !important;
  margin-top: 10px;
  transition: 0.4s;
  border-radius: 4px;
  text-align: center;
  transition: 0.4s;
  border: 1px solid var(--white-color);
  display: inline-block;
 }
 .loginBtnFooter:hover{
  color: var(--primary-color) !important;
  background-color: var(--white-color) !important;
  border: 1px solid var(--white-color);
text-decoration: none;
 }
.gold-text{
  color: var(--goldGradient1);
  font-weight: 800;
  font-size: 38px;
  line-height: 50px;
  text-transform: uppercase;
}
.gold-text span {
  background: -webkit-linear-gradient(
    45deg,
    var(--goldGradient1),
    var(--goldGradient2) 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.platinum-text{
  color: var(--platinumGradient1);
  font-weight: 800;
  font-size: 38px;
  line-height: 50px;
  text-transform: uppercase;
}
.platinum-text span {
  background: -webkit-linear-gradient(
    45deg,
    var(--platinumGradient1),
    var(--platinumGradient2) 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rgstrFormBox
{
  width: 360px;
    background-color: #fff;
    padding: 20px 35px;
    border-radius: 8px;
}
.bannerpara {
  font-size: 15px;
  margin-top: 15px;
}
.innersection {
  padding: 10px 0 70px;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
.primaryCaption {
  font-size: 18px;
  color: var(--captionprimary);
  font-weight: 600;
  margin-bottom: 20px;
}
ul.earningliIndex {
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
ul.earningliIndex li {
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid transparent;
  padding: 12px 8px 20px;
  background-color: rgb(250, 250, 250);
  width: calc(100% / 6 - 16px);
  margin: 8px;
  text-align: center;
  transition: 0.4s;
}
ul.earningliIndex li:hover {
  box-shadow: 4px 8px 16px 0 rgb(0 0 0 / 7%);
}
ul.earningliIndex li img {
  width: 60px;
  display: block;
  margin: 0 auto;
}
.erngCoin {
  box-sizing: border-box;
  margin: 10px 0px 7px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: rgb(30, 35, 41);
}
.erngapr {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgb(30, 35, 41);
}
.erngpercentage {
  box-sizing: border-box;
  font-weight: 600;
  color: rgb(3, 166, 109);
  font-size: 20px;
  line-height: 28px;
}
.packgeImg {
  width: 36px;
}
.tablesubscribeBtn {
  color: var(--white-color) !important;
  padding: 7px 14px !important;
  margin-top: 10px;
  transition: 0.4s;
  border-radius: 4px;
  text-align: center;
  transition: 0.4s;
  background-color: var(--primary-color);
  font-weight: 500;
  float: right;
}
.tableScrollerholder {
  width: 100%;
  overflow-x: auto;
}
.tableScrollerholder table {
  min-width: 600px;
}
.packageTableIndx thead tr th {
  background-color: #f9f9fa;
  border-bottom: 0px solid #000;
  border-top: 0px solid #000;
  font-weight: 600;
}
.packageTableIndx tr td {
  color: #1e1e1e;
  font-weight: 500;
  vertical-align: middle;
  border-top: 0px solid #000;
}
.aprPersntage {
  color: rgb(3, 166, 109);
}
.faqbg {
  background-color: #fafafa;
}

.h2title {
  font-size: 22px;
  color: var(--captionprimary);
  font-weight: 600;
  margin-bottom: 20px;
}
.smallCaption {
  font-size: 15px;
  color: var(--captionprimary);
  font-weight: 600;
  margin-bottom: 10px;
}
.mt-60 {
  margin-top: 60px;
}
.viewallBtn {
  color: var(--primary-color);
  margin-top: 20px;
  display: block;
  width: 120px;
  margin: 32px auto;
}
.viewallBtn:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 0px;
  border: 0;
  border-bottom: 1px solid #eaecef;
}

#main #faq .card .card-header {
  border: 0;
  padding: 10px 0;
  background: transparent;
}

#main #faq .card .card-header .btn-header-link {
  display: block;
  text-align: left;
  color: #222;
  padding: 5px 20px;
}
#main #faq .card .card-header .btn-header-link:after {
  content: "\EA78";
  font-family: remixicon !important;
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\EA4E";
}

#main #faq .card .collapsing {
  line-height: 30px;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  line-height: 30px;
  color: #222;
}
.footersection {
  padding: -9px 0 0px;
  background-color: #240202;
}
.spc li{

}
.footersection p {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
}


.videoSection
{
  padding: 25px;
  position: relative;
}
.videoSection:after
{
content: '';
position: absolute;
width: 44px;
    height: 44px;
    top: 10px;
    right: 10px;
border-right: 6px var(--primary-color) solid;
border-top: 6px var(--primary-color) solid;
}
.videoSection img{
  cursor: pointer;
}
.videoBtnBox
{
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: var(--primary-color);
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.videoBtnBox i
{
  color: #fff;
  font-size: 27px;
  border: 1px solid #fff;
  float: left;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
 }
.videoBtnBox:after {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  border: 1px solid #fff;
  -webkit-animation: mapRipple 1.3s linear infinite;
  animation: mapRipple 1.3s linear infinite;
}
@keyframes mapRipple {
  0% {
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
  opacity: 1;
}
100% {
  transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
  opacity: 0;
}
}
.videoSection .thumbnailCaption
{
  color: #fff;
  position: absolute;
  bottom: 26px;
  left: 80px;
  width: calc(100% - 120px);
}
.videoPopup
{
  width: 95% !important;
  max-width: 800px !important;
}
.videoPopup .modal-content
{
background-color: transparent !important;
border: 0;
}
.videoPopup .close

{
  float: right;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: absolute;
  right: 0;
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 999;
  line-height: 30px;
  opacity: 1;
  text-align: center;
}
.sectionpadding
{
  padding: 80px 0;
  background: url("../../../../src/images/imgcontent.jpg");
}
.speciAnimtn
{
  position: absolute;
  height: 500px;
  width: 500px;
  z-index: 1;
  left: 20px;
  top: 50px;
}
.speciAnimtn::after
{
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  position: absolute;
  opacity: .4;

}
.speciAnimtn::before
{
  content: "";
  height: 85%;
  width: 85%;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  position: absolute;
  opacity: .6;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #ccc;
}
.speciAnimtnBuble1
{
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid var(--primary-color);
  right: -10px;
  top: 50%;
  animation: speciAnimtnBublescale infinite;
  animation-duration: 4s;
}
.speciAnimtnBuble2
{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid var(--primary-color);
  right: 50%;
  top: 6%;
  animation: speciAnimtnBublescale infinite;
  animation-duration: 4s;
}
.speciAnimtnBuble3
{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid var(--primary-color);
  left: -3px;
  top:60%;
  animation: speciAnimtnBublescale infinite;
  animation-duration: 8s;
}


@keyframes speciAnimtnBublescale {
  0%   {transform: scale(1);}
  50% {transform: scale(1.3);background-color: #fff;}
  100%   {transform: scale(1);}
}
.speci 
{
  display: block;
  margin-top: 50px;
  width: 100%;
  float: left;
  padding: 60px;
  position: relative;
  z-index: 2;
}

.speci li
{
  width: calc(100% / 2 - 24px);
  margin: 8px 12px;
  display: flex;
  flex-wrap: wrap;
  background: url("../../../../src/images/rgstrFrmBg.webp") var(--primary-color) no-repeat;
  background-size: 100%;
  padding: 35px 20px;
  border-radius: 8px;
  float: left;
  height: 208px;
}
.speci li:nth-child(2), .speci li:nth-child(4)
{
  margin-top: -105px;
}
.iconBox
{
  width: 22px;
  height: 24px;
  text-align: center;
  background-color: #fff;
  border-radius: 7px;
  line-height: 26px;
  color: var(--primary-color);
  display: block;
}
.speci li .CntntBox
{
  width:100%;
  margin-top: 14px;
}

.speci li .CntntBox h2
{
  
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-left: 32px;
  margin-top: -90px;
  line-height: 15px;

}
.speci li .CntntBox p
{
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 0;
  color: #e3d2d2;
  max-height: 36px;
    
}
@media only screen and (min-width: 768px) and (max-width: 990.98px) {
  .speci
  {
    padding: 10px;
  }

.speciAnimtn {
  height: 400px;
  width: 400px;
  left: 20px;
  top: 50px;
}
}
/* ********************************************************* */
@media (max-width: 767.98px) {
  .Bannertitle {
    font-size: 31px;
    line-height: 41px;
  }
  .rowreverseMobile {
    flex-direction: column-reverse;
  }

  .dxstoxLNav .navbar-collapse {
    flex-basis: 100%;
  }
  .bannersection {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .loginBtn {
    margin-left: 0;
  }
  .bannerContainer:before
  {
    display: none;
  }
  .rgstrFormBox {
    width: 100%;
    background-color: #efefef;
    margin: auto;
}
.rgstrFormBox .css-14gng26 {
display: none;
}

.bannerContainer {
  margin-top: 0;
}
}
.bannerAlgmnt {
  padding: 65px 0;
}s
/* *********************************** */
.points li{
  display: flex;
  padding: 10px;
}
.points img{
  width: 20px;

}
.package-subhead h6{
  font-size: 0.8rem;
}
.divider{
  width: 2px;
  height: 25px;
  margin: auto;
  background-color: #f2f2f2;
  border-radius: 18px;
}
.outer-divider{
  margin: 2rem 0rem 0rem 0rem;
}
.normalHead{
  font-weight: 800;
    font-size: 38px;
    line-height: 50px;
    text-transform: uppercase;
    color: var(--balck-color);
}
.normalHead span{
  background: -webkit-linear-gradient(
    45deg,
    var(--btnGradient1),
    var(--btnGradient2) 80%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.say-human{
  border: dashed 3px #cccccc3d;
    padding: 23px;
    border-radius: 16px;
    background-color: #fafafa;
}
.font-weight-800{
  font-weight: 800;
}
.font-weight-700{
  font-weight: 700;
}
.bg-light-gold{
  background-color: #ecb9021a;
  padding: 1rem;
}
.quotes{
  line-height: 1.5;
    font-weight: 600;
    font-family: cursive;
    letter-spacing: 2px;
}
/* .banner
{
  width: 100%;
    background-image: url("/src/images/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
} */


.bannerImgg
{
  position: relative;
  z-index: 0;
  width: 100%;
  margin-top: -90px;
}
.bannerBox
{
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 130px);
  justify-content: center;
}
.bannerContent
{
 width: 100%;
 margin: auto;
 max-width: 600px;
 color: var(--primary-Fontcolor);
}
.prmrycolr
{
  color: var(--primary-color);
}
.bannerBox h1
{
  font-size: 35px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;

}
.bannerBox h2
{
  font-size: 19px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}
.bannerBox p
{
  font-size: 14px;
  color: #9f9f9f;
  font-weight: 400;
}
.subscribeBox
{
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
margin: 25px auto 0;

}
.subscribeBox input
{
width: calc(100% - 105px);
border-radius: 4px;
height: 40px;
background: transparent;
    border: 1px solid #FFFF00;
    padding-left: 10px;
    color: #fff;
    outline: none;
}
.subscribeBox .sbmtBtn
{
  width: 100px;
  margin-left: 5px;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  background-color: #ffff00ad;
  border: 0;
  outline: none;

}

@media (max-width: 768px) {

.loginBtn {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  margin-top: 13px;
  float: right;
    padding: 6px 15px !important;
    font-size: 13px;
}
.loginBtn:hover{
  color: #fff !important;
  border: 1px solid var(--primary-color);
background-color: var(--primary-color);
}
}
/* ********************************************************* */
@media (max-width: 568px) {
.speci 
{
  padding: 10px;
}
.speci li {
  width: calc(100% / 2 - 24px);
  margin: 8px 12px;
  padding: 20px 10px;
  float: left;
  background-size: cover;
}
.speciAnimtn {
  height: 350px;
  width: 350px;
  left: 0;
  right: 0;
  margin: auto;
  top: 75px;
}
.speci li:nth-child(2), .speci li:nth-child(4) {
  margin-top: -79px;
}
.videoSection {
  padding: 0px;
  margin-bottom: 20px;
}
.videoSection .thumbnailCaption
{
  display: none;
}
.videoSection:after {
  top: -10px;
  right: -10px;
}

.contentBox
{
  background: url("../../../../src/images/rgstrFrmBg.webp");
  padding: 50px 60px;
  background-size: cover;
  text-align: center;
  color: #fff;
}
element.style {
  
}

}
.boxValues{
  width: 68rem;
  height: 130px;
  padding: 50px;
  border: 3px solid black;
}
.backBox {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #f3f3f38a;
}
.button-21 {
  align-items: center;
  appearance: none;
  background-color: #0b1013;
  background-image: linear-gradient(1deg, #34343e, #3d4145 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-21:active,
.button-21:focus {
  outline: none;
}

.button-21:hover {
  background-position: -20px -20px;
  color: #ffffffe5;
}

.button-21:focus:not(:active) {
  box-shadow: rgb(253, 253, 253) 0 0 0 .125em;
}
.textCaption{
  margin-top: 0;
  margin-bottom: 13px;
  font-size: 15px;
  color: #1a1717c4;
  font-weight: 300;
  line-height: 18px;
}

.bannersectionone {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #f5f5f5;
}

.BoxSectionamount
{
  /* background: url("../../../../src/images/rgstrFrmBg.webp"); */
   
  /* padding: 45px 60px; */
  background-size: cover;
  background-color: white;
  text-align: center;
  color: #0a0303;
  border: 2px solid rgba(9, 9, 10, 0.804);
}

.BoxSectionamount h2{
  color: #000;
  font-weight: bold;
  
}